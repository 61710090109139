import React from "react";

const AffiliateButton = ({ link = "#" }) => {
  return (
    <a
      href={link}
      className="flex flex-row justify-center items-center gap-1.5 w-full bg-[#2d98da] rounded-[4px] font-[400] text-white py-2 cursor-pointer"
      target="_blank"
      rel="noreferrer"
    >
      Daftar Jadi Rekanan
    </a>
  );
};

export default AffiliateButton;
