import { combineReducers } from "redux";
import general from "./generalReducer";
import user from "./userReducer";

const appReducer = combineReducers({
  general,
  user,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
