import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { IoMdLogIn } from "react-icons/io";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useLoading } from "../components/LoadingSpinner";
import { auth, db } from "../libs/firebase";
import { isEmpty } from "lodash";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().min(6).required("Password is required"),
});

const Login = () => {
  const { currentUser } = useSelector((state) => state.user);
  const {
    addNotification,
    showLoadingSpinner,
    hideLoadingSpinner,
    handleError,
  } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      navigate("/");
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      showLoadingSpinner();

      const { email, password } = values;
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = res.user;

      const checkUserChats = await getDoc(doc(db, "userChats", user.uid));
      if (!checkUserChats.exists()) {
        await setDoc(doc(db, "userChats", user.uid), {});
      }

      addNotification({
        type: "success",
        message: "Login successfully!",
      });
      hideLoadingSpinner();
      navigate("/");
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  return (
    <div
      className="bg-[#f5f5f5] w-full min-h-screen flex items-center bg-cover bg-center px-4"
      style={
        {
          // backgroundImage: `url(${require("../assets/undraw_Texting_re_l11n.png")})`,
        }
      }
    >
      <div className="bg-white w-full md:w-[450px] flex flex-col items-center border border-[#e1e8ed] mx-auto pb-4 rounded-lg">
        <div className="w-full bg-[#467CBB] py-6 px-4 rounded-t-lg">
          <h2 className="text-lg text-center text-white font-semibold">
            PintuDagang
          </h2>
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: "",
            password: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue, errors, values }) => (
            <div className="bg-white w-full px-6 md:px-8 mb-4">
              <div className="border-l-4 border-[#467CBB] my-6 px-2">
                <h4 className="text-[#111] text-left text-xl font-bold mt-5 mb-4 leading-5">
                  Login
                </h4>
              </div>
              <div className="w-full form-group mb-4">
                <label className="block font-medium text-[#2C3A47] mb-1">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="eg. janedoe@gmail.com"
                  className="text-[#737373] pb-1.5 w-full focus:outline-none border-b border-[#c4c4c4]"
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  value={values.email}
                />
                {errors.email && (
                  <p className="text-[#ff4757] text-[12px]">{errors.email}</p>
                )}
              </div>
              <div className="form-group">
                <label className="block font-medium text-[#2C3A47] mb-1">
                  Password
                </label>
                <input
                  type="password"
                  placeholder="*********"
                  className="text-[#737373] pb-1.5 w-full focus:outline-none border-b border-[#c4c4c4]"
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  value={values.password}
                />
                {errors.password && (
                  <p className="text-[#ff4757] text-[12px]">
                    {errors.password}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="flex flex-row justify-center items-center gap-1 bg-[#22a6b3] text-white text-lg font-medium w-full py-2 mt-8 rounded-md"
                onClick={handleSubmit}
              >
                <IoMdLogIn size={22} />
                Sign In
              </button>
              <p className="text-[12px] text-right text-[#3498db] mt-1.5">
                <Link to="/forgotPassword">Forgot Password</Link>
              </p>
            </div>
          )}
        </Formik>
        <p className="text-[14px] mb-4">
          Don't have an account?{" "}
          <Link to="/register" className="text-[#4834d4] underline">
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
