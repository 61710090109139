import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import { signOut } from "firebase/auth";
import { USER } from "../store/actions/actionTypes";
import { auth } from "../libs/firebase";
import { useLoading } from "./LoadingSpinner";

const Logout = () => {
  const { addNotification } = useLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <button
      className="flex flex-row justify-center items-center gap-1.5 w-full bg-[#e74c3c] rounded-[4px] font-medium text-white py-2 cursor-pointer"
      onClick={() => {
        signOut(auth);
        addNotification({
          type: "success",
          message: "You have been logged out!",
        });
        dispatch({ type: USER.SET_CURRENT_USER, payload: {} });

        navigate("/login");
      }}
    >
      <MdOutlineLogout size={22} />
      Logout
    </button>
  );
};

export default Logout;
