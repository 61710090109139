import React, { useState, useRef } from "react";
import { AiOutlineSend } from "react-icons/ai";
import {
  arrayUnion,
  getDoc,
  setDoc,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { nanoid } from "nanoid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { TbPaperclip } from "react-icons/tb";
import { IoMdClose } from "react-icons/io";
import FormModal from "./Modal";
import { db, storage } from "../libs/firebase";
import useAutosizeTextArea from "../libs/hooks/useAutosizeTextArea";
import { ALLOWED_MIME_TYPES } from "../libs/constants";
import { getMainTopic } from "../libs/strings";
import { useLoading } from "./LoadingSpinner";

const ChatInput = ({ scrollToBottom }) => {
  const [text, setText] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const contactId = searchParams.get("contactId");
  const topicId = searchParams.get("topicId");
  const { showLoadingSpinner, hideLoadingSpinner, handleError } = useLoading();

  const { currentUser } = useSelector((state) => state.user);

  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, text);

  const handleSend = async () => {
    if (!text && !attachment) return;
    showLoadingSpinner();
    try {
      let combinedId = currentUser.uid;

      if (contactId) {
        combinedId = contactId;
      }

      const newTopicId = nanoid();

      if (topicId) {
        combinedId += `_topic=${topicId}`;

        await updateDoc(doc(db, "userTopicHistories", topicId), {
          queryText: `${currentUser.name} ${getMainTopic(text)}`.toLowerCase(),
          readStatus: "unread",
          lastReadBy: currentUser.uid,
          _updatedAt: dayjs().toISOString(),
        });
      } else {
        combinedId += `_topic=${newTopicId}`;
        await initChat(newTopicId);
      }

      if (attachment) {
        const storageRef = ref(storage, uuid());
        const uploadTask = uploadBytesResumable(storageRef, attachment);
        const uploadSnapshot = await uploadTask;
        const downloadURL = await getDownloadURL(uploadSnapshot.ref);

        await updateDoc(doc(db, "chats", combinedId), {
          messages: arrayUnion({
            id: uuid(),
            text,
            senderId: currentUser.uid,
            topicId: topicId || newTopicId,
            displayName: currentUser.name,
            date: Timestamp.now(),
            attachment: downloadURL,
            attachmentDetail: {
              size: attachment.size,
              type: attachment.type,
              name: attachment.name,
            },
          }),
        });
      } else {
        await updateDoc(doc(db, "chats", combinedId), {
          messages: arrayUnion({
            id: uuid(),
            text,
            senderId: currentUser.uid,
            topicId: topicId || newTopicId,
            displayName: currentUser.name,
            date: Timestamp.now(),
          }),
        });
      }

      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [combinedId + ".lastMessage"]: {
          text,
          topicId: topicId || newTopicId,
        },
        [combinedId + ".date"]: serverTimestamp(),
        _createdAt: dayjs().toISOString(),
        _updatedAt: dayjs().toISOString(),
      });

      // await updateDoc(doc(db, "userChats", data.user.uid), {
      //   [data.chatId + ".lastMessage"]: {
      //     text,
      //   },
      //   [data.chatId + ".date"]: serverTimestamp(),
      // });

      setText("");
      setAttachment(null);
      setIsModalVisible(false);

      if (!topicId) {
        searchParams.set("topicId", newTopicId);
        setSearchParams(searchParams);
      }

      if (scrollToBottom) {
        scrollToBottom();
      }
    } catch (err) {
      handleError(err);
      hideLoadingSpinner();
    }
    hideLoadingSpinner();
  };

  const initChat = async (topicId) => {
    let combinedId = currentUser.uid;
    combinedId += `_topic=${topicId}`;

    if (contactId) {
      combinedId = `${contactId}_topic=${topicId}`;
    }

    const res = await getDoc(doc(db, "chats", combinedId));
    if (!res.exists()) {
      await setDoc(doc(db, "chats", combinedId), { messages: [] });
      await setDoc(doc(db, "userTopicHistories", topicId), {
        id: uuid(),
        topicId,
        topicName: getMainTopic(text),
        userId: currentUser.uid,
        displayName: currentUser.name,
        queryText: `${currentUser.name} ${getMainTopic(text)}`.toLowerCase(),
        date: serverTimestamp(),
        lastReadBy: currentUser.uid,
        readStatus: "unread",
        _createdAt: dayjs().toISOString(),
        _updatedAt: dayjs().toISOString(),
      });

      //update both users' userChats data
      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [combinedId + ".userInfo"]: {
          uid: currentUser.uid,
          displayName: currentUser.name,
          topicId,
          // photoURL: currentUser.photoURL,
        },
        [combinedId + ".date"]: serverTimestamp(),
        _createdAt: dayjs().toISOString(),
        _updatedAt: dayjs().toISOString(),
      });

      // await updateDoc(doc(db, "userChats", user.uid), {
      //   [combinedId + ".userInfo"]: {
      //     uid: currentUser.uid,
      //     displayName: currentUser.displayName,
      //     photoURL: currentUser.photoURL,
      //   },
      //   [combinedId + ".date"]: serverTimestamp(),
      // });
    }
  };

  const handleUploadFile = (e) => {
    try {
      const file = e.target.files[0];
      if (!ALLOWED_MIME_TYPES.includes(file.type)) {
        throw new Error("File extension not allowed!");
      }
      if (file.size > 1024 * 1024 * 1) {
        throw new Error("File size must be less than 5MB");
      }

      setAttachment(file);
      let reader = new FileReader();
      reader.onloadend = async () => {
        setPreviewImage(reader.result);
        setIsModalVisible(true);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-[#fdfdfd] dark:bg-gray-800 md:!bg-transparent dark:md:bg-vert-dark-gradient pt-2">
      <div className="md:ml-[260px] mt-2 px-4 bg-[#fdfdfd]">
        <div className="flex flex-row items-center gap-2 lg:max-w-3xl xl:max-w-3xl mx-auto">
          <label htmlFor="img-upload" className="img-upload-input">
            <TbPaperclip
              className="cursor-pointer text-[#3e3e3e]"
              size={26}
              // onClick={() => setIsModalVisible(true)}
            />
          </label>

          <input
            id="img-upload"
            type="file"
            className="hidden"
            onChange={handleUploadFile}
          />
          <div className="flex flex-col w-full py-2 md:pl-4 relative border border-black/10 bg-white lg:max-w-3xl xl:max-w-3xl mx-auto rounded-md">
            <textarea
              ref={textAreaRef}
              className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-4 md:pl-0 py-1 focus:outline-none"
              rows={1}
              placeholder="Type a message"
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              onChange={(e) => setText(e.target.value)}
              value={text}
              style={{
                maxHeight: "200px",
              }}
            ></textarea>
            <AiOutlineSend
              className="absolute top-0 bottom-0 right-4 my-auto cursor-pointer"
              size={20}
              onClick={handleSend}
            />
          </div>
        </div>
        <div className="px-3 pt-2 pb-3 text-center text-xs text-gray-600 dark:text-gray-300 md:px-4 md:pt-3 md:pb-6">
          <span>Copyright © 2023 PintuDagang </span>
        </div>
      </div>
      <FormModal
        containerClassName="w-full md:w-[600px] mt-0 rounded-sm bg-white overflow-y-scroll"
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        zIndex={"z-[999]"}
      >
        <div
          className={`relative ${
            previewImage ? "md:h-[624px]" : "md:h-[400px]"
          } flex flex-col`}
        >
          <div className="header bg-[#f2f6fc] border-b border-[#dee2e6] px-4 py-4 absolute top-0 right-0 left-0 flex flex-row items-center justify-between">
            <h2 className="font-inter text-[#041e49] text-lg font-medium">
              Preview
            </h2>
            <IoMdClose
              className="cursor-pointer"
              color="#98A7B5"
              size={22}
              onClick={() => {
                setIsModalVisible(false);
              }}
            />
          </div>
          <div className="flex flex-col justify-center items-center bg-white w-full h-full my-auto">
            {previewImage ? (
              <img
                src={previewImage}
                className="w-full h-[550px] object-cover"
                alt="preview"
              />
            ) : (
              <p className="text-2xl">Preview not available</p>
            )}
            <div className="w-full px-4 py-2 my-auto">
              <div className="flex flex-col w-full py-2 md:pl-4 relative border border-black/10 bg-white w-full mx-auto rounded-md">
                <textarea
                  ref={textAreaRef}
                  className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-4 md:pl-0 py-1 focus:outline-none"
                  rows={1}
                  placeholder="Type a message"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  style={{
                    maxHeight: "200px",
                  }}
                ></textarea>
                <AiOutlineSend
                  className="absolute top-0 bottom-0 right-4 my-auto cursor-pointer"
                  size={20}
                  onClick={handleSend}
                />
              </div>
            </div>
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default ChatInput;
