import { useDispatch } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { USER } from "../store/actions/actionTypes";
import { auth, db } from "../libs/firebase";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      getUserDetail(user);
    });

    return () => {
      unsub();
    };
  }, []);

  const getUserDetail = async (user) => {
    if (!user) return;
    const qry = query(
      collection(db, "users"),
      where("email", "==", user.email)
    );
    try {
      const querySnapshot = await getDocs(qry);
      querySnapshot.forEach((doc) => {
        dispatch({ type: USER.SET_CURRENT_USER, payload: doc.data() });
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
