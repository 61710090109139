import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Navbar from "./Navbar";
import { AuthContext } from "../context/AuthContext";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (isEmpty(currentUser)) {
      return navigate("/login");
    }
  });

  return (
    <>
      <Navbar />
      {children}
    </>
  );
};

export default Layout;
