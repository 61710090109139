const _ = require("lodash");

export const getFirstCharacters = (words) => {
  if (!words) return "";
  const wordArray = words.split(" ");
  const firstCharacters = _.map(wordArray, (word) => _.head(word));

  if (firstCharacters.length > 2) {
    return `${firstCharacters[0]}${
      firstCharacters[firstCharacters.length - 1]
    }`;
  }
  return firstCharacters.join("");
};

export const getMainTopic = (words) => {
  if (!words) return "";
  let maxLength = 15;

  if (words.length > maxLength) {
    words = words.substring(0, maxLength);
    let lastWhitespaceIndex = words.lastIndexOf(" ");
    words = words.substring(0, lastWhitespaceIndex);
  }
  return words;
};
