export const USER_WITH_ROLE_ADMIN = {
  mOQZM8YmiRT8mlQcvNANxghHuTQ2: true,
};

export const ALLOWED_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/tiff",
  "image/tiff",
  "image/svg+xml",
  "image/webp",
  "image/x-icon",
  "image/heif",
  "image/heif",
  "image/vnd.adobe.photoshop",
];
