import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LoadingProvider } from "./components/LoadingSpinner";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
// import VerifyEmail from "./pages/VerifyEmail";
// import Videocall from "./pages/Videocall";
import ChatRoom from "./pages/ChatRoom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/chat",
    element: <ChatRoom />,
  },
]);

function App() {
  return (
    <LoadingProvider>
      <RouterProvider router={router} />
      <ToastContainer />
    </LoadingProvider>
  );
}

export default App;
