import React from "react";
import ChatBox from "../components/ChatBox";
import TopicHistory from "../components/TopicHistory";
import Layout from "../components/Layout";

const ChatRoom = () => {
  return (
    <Layout>
      <div className="relative w-full h-full flex">
        <TopicHistory />
        <ChatBox />
      </div>
    </Layout>
  );
};

export default ChatRoom;
