import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { BiMessageDetail } from "react-icons/bi";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../libs/firebase";
import Logout from "./Logout";
import AffiliateButton from "./AffiliateButton";

const TopicHistory = () => {
  const [userTopicHistories, setUserTopicHistories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topicId = searchParams.get("topicId");

  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserTopicHistory();
  }, [topicId]);

  const handleClickTopic = (newTopicId) => () => {
    if (newTopicId) {
      searchParams.set("topicId", newTopicId);
    } else {
      searchParams.delete("topicId");
    }
    setSearchParams(searchParams);
  };

  const getUserTopicHistory = async () => {
    setLoading(true);
    try {
      const qry = query(
        collection(db, "userTopicHistories"),
        where("userId", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(qry);
      const topics = [];
      querySnapshot.forEach((doc) => {
        topics.push(doc.data());
      });
      setUserTopicHistories(topics);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <div className="relative dark flex-shrink-0 bg-gray-900 hidden md:flex md:w-[260px] md:flex-col z-[99999]">
      <div className="flex h-screen min-h-0 flex-col">
        <div className="flex h-full w-full flex-1 flex-col p-2">
          <button
            className="flex items-center justify-center gap-1 text-white border border-gray-500 rounded-lg mb-4 px-4 py-2"
            onClick={handleClickTopic("")}
          >
            <HiOutlineChatBubbleOvalLeftEllipsis size={22} />
            New Message
          </button>
          <div className="relative px-2 pb-36 overflow-scroll no-scrollbar">
            <ol>
              {loading && userTopicHistories.length < 1 && (
                <li>
                  <p className="text-center text-white">Loading...</p>
                </li>
              )}
              {userTopicHistories.map((topic) => (
                <li key={topic.id}>
                  <button
                    onClick={handleClickTopic(topic.topicId)}
                    className="flex flex-row items-center gap-2 py-3"
                  >
                    <BiMessageDetail className="text-white text-2xl" />
                    <span className="text-white truncate">
                      {topic.topicName}
                    </span>
                  </button>
                </li>
              ))}
              {/* <li>
                <a href="#" className="flex flex-row items-center gap-2 py-3">
                  <BiMessageDetail className="text-white text-2xl" />
                  <span className="text-white truncate">
                    Jelaskan Fisika Kuantum
                  </span>
                </a>
              </li> */}
            </ol>
          </div>
        </div>
      </div>
      <div className="footer absolute bottom-0 left-0 right-0 flex flex-col gap-4 p-4 bg-[#101827]">
        <AffiliateButton />
        <hr className="border-[#6b7280]" />
        <Logout />
      </div>
    </div>
  );
};

export default TopicHistory;
