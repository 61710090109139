import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoCloseCircle } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const Modal = (props) => {
  const handleClickModalMask = (e) => {
    if (e) e.preventDefault();
    if (props.closeOnBackdrop && props.onClose) {
      props.onClose();
    }
  };

  const handleClickModalContent = (e) => {
    if (e) e.stopPropagation();
  };

  const handleEscapeKeydown = (e) => {
    if (e.keyCode === 27) {
      handleClickModalMask();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKeydown, false);
    return () => {
      document.removeEventListener("keydown", handleEscapeKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AnimatePresence>
        {props.visible ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.25,
            }}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
            className={`${props.zIndex} modal-mask fixed top-0 left-0 bottom-0 right-0 h-screen w-screen block overflow-y-scroll bg-blur`}
            onClick={handleClickModalMask}
          ></motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {props.visible ? (
          <motion.div
            initial={{ opacity: 0, y: "-2%" }}
            animate={{ opacity: 1, y: "0%" }}
            exit={{ opacity: 0, y: "2%" }}
            transition={{
              duration: 0.15,
            }}
            className={twMerge(
              `${props.zIndex} fixed top-0 left-0 bottom-0 right-0 h-screen w-screen block overflow-y-scroll bg-blur`,
              props.modalClassName
            )}
            onClick={handleClickModalMask}
          >
            <div
              className={twMerge(
                `mx-auto bg-white mt-24 md:mt-8 lg:mt-8 mb-8 overflow-hidden shadow-lg w-11/12 ${
                  props.withoutRadius ? "" : "rounded-lg"
                } ` +
                  (!props.size
                    ? "sm:w-4/12"
                    : props.size === "sm"
                    ? "sm:w-3/12"
                    : props.size === "md"
                    ? "sm:w-5/12"
                    : props.size === "lg"
                    ? "sm:w-9/12"
                    : props.size === "xl"
                    ? "sm:w-10/12"
                    : "w-4/12") +
                  ` ${props.containerClassName}`
              )}
            >
              <div className="modal-content" onClick={handleClickModalContent}>
                {props.children}
              </div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

const FormModal = ({
  visible,
  onClose,
  onSubmit,
  customTitle,
  title,
  children,
  size,
  modalClassName,
  containerClassName,
  closeLabel,
  pageLoading,
  hideSubmitButton,
  hideCloseButton,
  zIndex = "z-50",
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    size={size}
    modalClassName={modalClassName}
    containerClassName={containerClassName}
    closeOnBackdrop={true}
    zIndex={zIndex}
    withoutRadius
  >
    <WithForm onSubmit={onSubmit} className="relative">
      {customTitle ??
        (title ? (
          <div className="modal-header flex text-md md:text-2xl py-5 px-6 border-b border-gray-300">
            <h4 className="text-xl font-inter">{title}</h4>
            {onClose ? (
              <a
                href="#"
                onClick={onClose}
                className="absolute top-0 right-0 mt-5 mr-6"
              >
                <IoCloseCircle className="text-red-500 transition duration-300 hover:text-red-800" />
              </a>
            ) : null}
          </div>
        ) : null)}
      <div className={"modal-body"}>{children}</div>

      {onSubmit ? (
        <div className="modal-footer bg-gray-100 py-4 mt-4 px-4 md:px-6 flex justify-end text-right">
          {!hideCloseButton && closeLabel ? (
            <button
              onClick={onClose}
              className="btn btn-danger w-20 md:w-32 btn-flat mr-3 md:mr-5"
            >
              {closeLabel || (
                <span>
                  <i className="fa fa-times-circle mr-2" /> Cancel
                </span>
              )}
            </button>
          ) : null}
          {!hideSubmitButton ? (
            <button
              disabled={pageLoading}
              type="submit"
              className="btn bg-[#2d98da] w-24 md:w-32 px-2 py-2 rounded-lg text-white"
            >
              {pageLoading ? (
                <span>Saving...</span>
              ) : (
                <span>
                  <i className="fa fa-save mr-2" /> Save
                </span>
              )}
            </button>
          ) : null}
        </div>
      ) : null}
    </WithForm>
  </Modal>
);

const WithForm = ({ onSubmit, children, ...props }) => {
  if (!onSubmit) return <div {...props}>{children}</div>;
  return (
    <form {...props} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default FormModal;
