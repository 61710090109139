import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { get } from "lodash";
import { useSearchParams } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { CgSoftwareDownload } from "react-icons/cg";
import { USER_WITH_ROLE_ADMIN, IMAGE_MIME_TYPES } from "../libs/constants";
import { getFirstCharacters } from "../libs/strings";
import { db } from "../libs/firebase";
import ChatInput from "./ChatInput";
import { useLoading } from "./LoadingSpinner";
import useWindowDimensions from "../libs/hooks/useWindowDimensions";

const WelcomeMessage = () => (
  <div className="w-full px-4 md:px-48 mx-auto flex flex-col items-center mt-24 h-full overflow-scroll">
    <h2 className="text-[#467CBB] text-4xl font-semibold mb-2">PintuDagang</h2>
    <h3 className="uppercase text-3xl font-semibold">AI Chatbot</h3>
    <div className="w-full grid grid-cols-1 md:grid-cols-3 mt-6 mb-2">
      <div className="col-span-1">
        <h4 className="text-center text-lg font-medium">Contoh</h4>
      </div>
      <div className="col-span-1">
        <h4 className="text-center text-lg font-medium">Kapabilitas</h4>
      </div>
      <div className="col-span-1">
        <h4 className="text-center text-lg font-medium">Batasan</h4>
      </div>
    </div>
    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">Please explain about you</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">Keep history chat per topic</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">Limit to 1 question per message</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">What is the meaning of life?</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">Online 24/7</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">May the answer is not accurate</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">Give me recommendation songs</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">Support upload image and file</h4>
      </div>
      <div className="col-span-1 bg-gray-100 p-3 rounded-md">
        <h4 className="text-center text-sm">May slow response</h4>
      </div>
    </div>
  </div>
);

const checkIfRoleAdmin = (currentUser, userId) => {
  if (!currentUser) return false;
  if (USER_WITH_ROLE_ADMIN[userId]) {
    return true;
  }
  return false;
};

const ChatBox = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const contactId = searchParams.get("contactId");
  const topicId = searchParams.get("topicId");
  const [messages, setMessages] = useState([]);
  const { showLoadingSpinner, hideLoadingSpinner, handleError } = useLoading();
  const [screenWidth] = useWindowDimensions();
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length > 0 && messagesEndRef.current) {
      scrollToBottom();
    }
  }, [messages, messagesEndRef.current, screenWidth]);

  useEffect(() => {
    if (!currentUser || !topicId) return;
    let combinedId = currentUser.uid + `_topic=${topicId}`;

    if (contactId) {
      combinedId = `${contactId}_topic=${topicId}`;
    }

    const unsub = onSnapshot(doc(db, "chats", combinedId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => unsub();
  }, [contactId, topicId, currentUser.uid, messagesEndRef.current]);

  useEffect(() => {
    if (topicId) {
      initChat();
    }
  }, [topicId]);

  const initChat = async () => {
    let combinedId = currentUser.uid;
    combinedId += `_topic=${topicId}`;

    if (contactId) {
      combinedId = `${contactId}_topic=${topicId}`;
    }

    const res = await getDoc(doc(db, "chats", combinedId));
    if (!res.exists()) {
      await setDoc(doc(db, "chats", combinedId), { messages: [] });

      //update both users' userChats data
      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [combinedId + ".userInfo"]: {
          uid: currentUser.uid,
          displayName: currentUser.name,
          // photoURL: currentUser.photoURL,
          topicId,
        },
        [combinedId + ".date"]: serverTimestamp(),
        _createdAt: dayjs().toISOString(),
        _updatedAt: dayjs().toISOString(),
      });

      // await updateDoc(doc(db, "userChats", user.uid), {
      //   [combinedId + ".userInfo"]: {
      //     uid: currentUser.uid,
      //     displayName: currentUser.displayName,
      //     photoURL: currentUser.photoURL,
      //   },
      //   [combinedId + ".date"]: serverTimestamp(),
      // });
    }
  };

  const downloadImageFromUrl = (url, attachmentDetail) => {
    showLoadingSpinner();
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = attachmentDetail.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        hideLoadingSpinner();
        console.log("File downloaded successfully.");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleError(error);
        hideLoadingSpinner();
      });
  };

  const renderAttachment = (message) => {
    if (!message.attachment) return null;

    if (IMAGE_MIME_TYPES.includes(get(message, "attachmentDetail.type", ""))) {
      return (
        <div className="w-64 h-64">
          <img
            src={message.attachment}
            alt="Attachment"
            className="object-cover h-full"
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col justify-center items-center bg-[#d4d4d4] w-56 h-48 rounded-sm">
          <p className="text-center text-lg">Preview not available</p>
          <CgSoftwareDownload
            size={32}
            className="cursor-pointer"
            onClick={() =>
              downloadImageFromUrl(message.attachment, message.attachmentDetail)
            }
          />
        </div>
      );
    }
  };

  return (
    <div className="flex h-screen max-w-full flex-1 flex-col">
      {topicId ? (
        <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
          <div className="flex flex-col items-center text-sm dark:bg-gray-800 overflow-scroll pb-24">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`group w-full text-gray-800 dark:text-gray-100 border-b border-black/10
                 dark:border-gray-900/50 dark:bg-gray-800 ${
                   checkIfRoleAdmin(currentUser, message.senderId)
                     ? "bg-[#fff7f1]"
                     : ""
                 }`}
              >
                <div className="text-base gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0 m-auto">
                  <div className="w-[30px] flex flex-col relative items-end">
                    {checkIfRoleAdmin(currentUser, message.senderId) ? (
                      <div className="bg-[#467CBB] px-1 py-0.5 md:px-2 md:py-2 rounded-sm">
                        <h4 className="font-semibold text-white">PD</h4>
                      </div>
                    ) : (
                      <div className="bg-gray-500 px-1 py-0.5 md:px-2 md:py-2 rounded-sm">
                        <h4 className="font-semibold text-white">
                          {getFirstCharacters(message.displayName)}
                        </h4>
                      </div>
                    )}
                  </div>
                  <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
                    <div className="flex flex-grow flex-col gap-3">
                      {renderAttachment(message)}
                      <div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
                        {message.text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
            {/* <div className="group w-full text-gray-800 dark:text-gray-100 border-b border-black/10 dark:border-gray-900/50 bg-[#fff7f1] dark:bg-gray-800">
              <div className="text-base gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0 m-auto">
                <div className="w-[30px] flex flex-col relative items-end">
                  <div className="bg-[#467CBB] px-1 py-0.5 md:px-2 md:py-2 rounded-sm">
                    <h4 className="font-semibold text-white">PD</h4>
                  </div>
                </div>
                <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
                  <div className="flex flex-grow flex-col gap-3">
                    <div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
                      6% dari 100 juta adalah 6 juta.
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <WelcomeMessage />
      )}
      <ChatInput scrollToBottom={scrollToBottom} />
    </div>
  );
};

export default ChatBox;
