import React, { useContext } from "react";
import { CgMenu } from "react-icons/cg";
import { AuthContext } from "../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const { isOpenSidebar } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const handleImage = () => {
    window.open(currentUser.photoURL, "_blank");
  };

  return (
    <div className="sticky top-0 z-10 flex items-center border-b border-white/20 bg-[#467CBB] py-2 text-gray-200 px-4 md:hidden">
      <CgMenu
        size={24}
        className="cursor-pointer"
        onClick={() => {
          dispatch({ type: "SET_OPEN_SIDEBAR", payload: !isOpenSidebar });
        }}
      />
      <h4 className="flex-1 text-center font-medium">PintuDagang</h4>
    </div>
  );
};

export default Navbar;
