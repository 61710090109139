import React from "react";
import Layout from "../components/Layout";
import ChatBox from "../components/ChatBox";
import Sidebar from "../components/Sidebar";

const Home = () => {
  return (
    <Layout>
      <div className="relative w-full h-full flex">
        <Sidebar />
        <ChatBox />
      </div>
    </Layout>
  );
};

export default Home;
