import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../libs/firebase";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;

    try {
      await sendPasswordResetEmail(auth, email);
      window.alert("Email sent!");
      navigate("/login");
    } catch (err) {
      setErr(true);
      console.log(err);
    }
  };

  return (
    <div className="bg-[#f5f5f5] w-full min-h-screen flex items-center bg-cover bg-center px-4">
      <div className="formWrapper mx-auto py-8 min-w-[400px]">
        <h2 className="text-left font-semibold text-xl">PintuDagang</h2>{" "}
        <span className="text-left">Please input your registered email</span>
        <form onSubmit={handleForgotPassword}>
          <input type="email" placeholder="Email" />
          <button type="submit" className="bg-[#22a6b3] ">
            Send Password Reset Link
          </button>
        </form>
        {err && <span className="error-msg">Something went wrong!</span>}
        <p>
          <Link to="/login">Back to Login Screen</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
