import React, { createContext, useContext, useState } from "react";
import get from "lodash/get";
import { toast } from "react-toastify";

const DEFAULT_LOADING_STATE = {
  children: null,
  visible: false,
};

const TOAST_TYPES = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warn",
};

export const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};
const LoadingSpinner = ({ visible }) => {
  return (
    <div>
      <div
        className="loader-wrapper"
        style={{
          visibility: visible ? "visible" : "hidden",
          opacity: visible ? 1 : 0,
        }}
      >
        <div className="loader" />
      </div>
      <style jsx="true">{`
        .loader-wrapper {
          -webkit-transition: visibility 0s linear 200ms, opacity 200ms linear; /* Safari */
          transition: visibility 0s linear 200ms, opacity 200ms linear;

          opacity: 1;
          position: fixed; /* Sit on top of the page content */
          display: block; /* Hidden by default */
          width: 100%; /* Full width (cover the whole page) */
          height: 100%; /* Full height (cover the whole page) */
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(
            243,
            243,
            243,
            0.4
          ); /* Black background with opacity */
          z-index: 99999997; /* Specify a stack order in case you're using a different order for other elements */
          cursor: pointer; /* Add a pointer on hover */
        }
        .loader {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          border: 3px solid #f3f3f3;
          border-top: 5px solid #757fef;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

const useBaseLoading = () => {
  const [loadingState, setLoadingState] = useState(DEFAULT_LOADING_STATE);

  const showLoadingSpinner = () => {
    setLoadingState({
      ...loadingState,
      visible: true,
    });
  };

  const hideLoadingSpinner = () => {
    setLoadingState({
      ...loadingState,
      visible: false,
    });
  };

  const addNotification = ({ type, message }) => {
    toast[get(TOAST_TYPES, type, "info")](message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleError = (error) => {
    hideLoadingSpinner();
    if (error.message) {
      if (Array.isArray(error.message)) {
        error.message = error.message.join("\n");
      } else if (error.message.indexOf("Network error") >= 0) {
        error.message = "Network error! Please try again later.";
      }
    } else {
      error.message = "Unknown error! Please try again later.";
    }
    toast.error(error.message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return {
    loadingState,
    showLoadingSpinner,
    hideLoadingSpinner,
    addNotification,
    handleError,
  };
};

export const LoadingProvider = ({ children }) => {
  const loading = useBaseLoading();

  return (
    <LoadingContext.Provider value={loading}>
      {children}
      <LoadingSpinner {...loading.loadingState} />
    </LoadingContext.Provider>
  );
};
