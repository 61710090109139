import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  where,
  and,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { IoFilterOutline } from "react-icons/io5";
import { HiOutlineSearch } from "react-icons/hi";
import { RxGear } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { BsCheck2All } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";
import { checkIfRoleAdmin } from "../libs/utils";
import FormModal from "./Modal";
import Logout from "./Logout";
import { useLoading } from "./LoadingSpinner";
import AffiliateButton from "./AffiliateButton";
import { db } from "../libs/firebase";
import useDebounce from "../libs/hooks/useDebounce";

const ChatHistory = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const { showLoadingSpinner, hideLoadingSpinner, handleError } = useLoading();
  console.log("currentUser", currentUser);
  useEffect(() => {
    getChats();
    getSettings();
  }, []);

  useDebounce(
    () => {
      getChats();
    },
    [searchText],
    1000
  );

  const getSettings = async () => {
    try {
      const res = await getDocs(collection(db, "settings"));
      if (res.size > 0) {
        setFormData({
          ...res.docs[0].data(),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getChats = async () => {
    setLoading(true);
    try {
      let qry = query(collection(db, "userTopicHistories"));

      if (searchText) {
        qry = query(
          collection(db, "userTopicHistories"),
          and(
            where("displayName", ">=", searchText),
            where("displayName", "<=", searchText + "\uf8ff")
          )
        );
      }

      const querySnapshot = await getDocs(qry);
      const newChats = [];
      querySnapshot.forEach((doc) => {
        newChats.push(doc.data());
      });
      setChats(newChats);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
    setLoading(false);
  };

  const handleClickChat = (chat) => () => {
    if (chat.topicId) {
      searchParams.set("contactId", chat.userId);
      searchParams.set("topicId", chat.topicId);
    } else {
      searchParams.delete("topicId");
    }
    setSearchParams(searchParams);
  };

  const handleSubmit = async (e) => {
    showLoadingSpinner();
    try {
      const res = await getDocs(collection(db, "settings"));
      if (res.docs.length > 0) {
        await setDoc(doc(db, "settings", res.docs[0].id), {
          ...formData,
          _updatedAt: dayjs().toISOString(),
        });
      } else {
        await addDoc(collection(db, "settings"), {
          ...formData,
          _id: uuid(),
          _createdAt: dayjs().toISOString(),
          _updatedAt: dayjs().toISOString(),
        });
      }

      setIsModalVisible(false);
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  return (
    <div className="relative dark flex-shrink-0 bg-gray-900 md:flex w-[260px] md:flex-col z-[99999]">
      <div className="flex h-screen min-h-0 flex-col">
        <div className="flex h-full w-full flex-1 flex-col p-2">
          <div className="flex justify-center items-center gap-4 my-2 md:my-4">
            <h1 className="text-2xl font-semibold text-white text-center">
              PintuDagang
            </h1>
            {checkIfRoleAdmin(currentUser) && (
              <RxGear
                color="#fff"
                size={22}
                className="cursor-pointer"
                onClick={() => {
                  setIsModalVisible(!isModalVisible);
                }}
              />
            )}
          </div>
          <div className="relative flex flex-row gap-2 form-group mb-6">
            <HiOutlineSearch
              color="#fff"
              className="absolute top-0 left-3 bottom-0 my-auto"
            />
            <input
              type="text"
              className="truncate form-control w-full bg-gray-800 border border-gray-500 rounded-lg pl-8 pr-4 py-1.5 text-white focus:outline-none"
              placeholder="Cari atau filter chat"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
            <IoFilterOutline
              color="#fff"
              className="cursor-pointer my-auto"
              style={{
                transform: showFilterBox ? "rotate(180deg)" : "rotate(0deg)",
              }}
              size={24}
              onClick={() => setShowFilterBox(!showFilterBox)}
            />

            <AnimatePresence initial={false}>
              {showFilterBox && (
                <motion.div
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1 },
                    collapsed: { opacity: 0 },
                  }}
                  className="absolute right-0 top-12 bg-white px-6 py-4 rounded-lg z-[9999]"
                  style={{
                    boxShadow:
                      "0 2px 5px 0 rgba(var(--shadow-rgb),.26),0 2px 10px 0 rgba(var(--shadow-rgb),.16)",
                  }}
                >
                  <button
                    className="pb-2"
                    onClick={() => setShowFilterBox(false)}
                  >
                    Pesan belum dibaca
                  </button>
                  <hr />
                  <button
                    className="pt-2"
                    onClick={() => setShowFilterBox(false)}
                  >
                    Pesan sudah dibaca
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative px-2 pb-36 overflow-scroll no-scrollbar">
            <ol>
              {loading && chats.length < 1 && (
                <li>
                  <p className="text-center text-white">Loading...</p>
                </li>
              )}
              {chats.map((chat) => (
                <li className="mb-4" key={chat.id}>
                  <div className="relative w-full flex flex-row gap-2 pb-2 border-b border-gray-600">
                    <div className="bg-gray-500 w-10 h-10 rounded-full"></div>
                    <div
                      className="contact-detail flex-1 overflow-hidden cursor-pointer"
                      onClick={handleClickChat(chat)}
                    >
                      <h4 className="w-28 truncate text-white font-medium">
                        {chat.displayName}
                      </h4>
                      <p className="text-sm text-[#c4c4c4] truncate">
                        {/* Anda: {chat.message} */}
                        {chat.topicName}
                      </p>
                    </div>
                    <h6 className="absolute right-0 font-medium text-white text-[10px]">
                      {dayjs.unix(chat.date.seconds).format("DD/MM/YYYY")}
                    </h6>
                    <BsCheck2All
                      size={18}
                      className={`absolute right-0 bottom-3 font-medium ${
                        chat.lastReadBy === currentUser.uid
                          ? "text-[#2e98da]"
                          : "text-[#c1c1c1]"
                      }`}
                    />
                  </div>
                </li>
              ))}
              {/* {DUMMY_MESSAGE.map((message) => (
                <li className="mb-4" key={message.id}>
                  <div className="relative w-full flex flex-row gap-2 pb-2 border-b border-gray-600">
                    <div className="bg-gray-500 w-10 h-10 rounded-full"></div>
                    <div className="contact-detail flex-1 overflow-hidden">
                      <h4 className="w-28 truncate text-white font-medium">
                        {message.name}
                      </h4>
                      <p className="text-sm text-[#c4c4c4] truncate">
                        Anda: {message.message}
                      </p>
                    </div>
                    <h6 className="absolute right-0 font-medium text-white text-[10px]">
                      26/09/2023
                    </h6>
                  </div>
                </li>
              ))} */}
            </ol>
          </div>
        </div>
      </div>
      <div className="footer absolute bottom-0 left-0 right-0 flex flex-col gap-4 p-4 bg-[#101827]">
        <AffiliateButton link={formData.affiliateLink} />
        <hr className="border-[#6b7280]" />
        <Logout />
      </div>
      <FormModal
        containerClassName="w-full md:w-[600px] mt-0 rounded-sm bg-white overflow-y-scroll"
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        zIndex={"z-[999]"}
      >
        <div className={`relative flex flex-col h-[400px]`}>
          <div className="header bg-[#f2f6fc] border-b border-[#dee2e6] px-4 py-4 absolute top-0 right-0 left-0 flex flex-row items-center justify-between">
            <h2 className="font-inter text-[#041e49] text-lg font-medium">
              Settings
            </h2>
            <IoMdClose
              className="cursor-pointer"
              color="#98A7B5"
              size={22}
              onClick={() => {
                setIsModalVisible(false);
              }}
            />
          </div>
          <div className="bg-white w-full h-full mt-24 px-8">
            <div className="form-group flex flex-row gap-4">
              <label className="text-medium text-[#041e49] font-medium ">
                Affiliate Link
              </label>
              <input
                type="text"
                placeholder="eg. https://..."
                className="flex-1 w-full py-2 px-4 border border-black/10 bg-transparent focus:ring-0 focus-visible:ring-0 dark:bg-transparent focus:outline-none rounded-md"
                value={formData.affiliateLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    affiliateLink: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="footer px-8 py-6 text-right">
            <button
              className="bg-[#22a6b3] text-white px-4 py-2 rounded-md"
              onClick={handleSubmit}
            >
              Simpan
            </button>
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default ChatHistory;
