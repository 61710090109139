import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import useWindowDimensions from "../libs/hooks/useWindowDimensions";
import TopicHistory from "./TopicHistory";
import ChatHistory from "./ChatHistory";

const renderSidebarContent = (currentUser) => {
  if (currentUser && currentUser.role === "admin") {
    return <ChatHistory />;
  }
  return <TopicHistory />;
};

const Sidebar = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [screenWidth] = useWindowDimensions();
  const { isOpenSidebar } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  return (
    <AnimatePresence>
      {screenWidth < 768 && isOpenSidebar && (
        <motion.div
          className="fixed top-0 right-0 left-0 bottom-0 z-[99999]"
          initial={{ width: 0 }}
          animate={{
            width: "100%",
          }}
          exit={{
            width: 0,
            transition: { delay: 0.2, duration: 0.3 },
          }}
        >
          <ChatHistory />
          <button
            className="absolute top-2 right-2"
            onClick={() =>
              dispatch({ type: "SET_OPEN_SIDEBAR", payload: !isOpenSidebar })
            }
          >
            <IoClose size={28} color="#fff" />
          </button>
          <div className="absolute inset-0 bg-[#111] opacity-25  z-[-9999]" />
        </motion.div>
      )}
      {screenWidth >= 768 && renderSidebarContent(currentUser)}
    </AnimatePresence>
  );
};

export default Sidebar;
