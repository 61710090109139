import { AUTH, USER } from "../actions/actionTypes";

const initialState = {
  currentUser: {},
  isLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case AUTH.LOGOUT:
      return {
        ...state,
        currentUser: {},
      };
    default:
      return state;
  }
};

export default userReducer;
